export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/tandoori-new-logo.png',
    banner_img_root : '/img/tandoori-banner5.jpg',
    resid : '80ce8de93f71d4b188e62d10fe56eff2',
    key_value : 'tandoorigrillbouldertickets',
    secret_value : 'tandoorigrillbouldertickets',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.09091660424!2d-105.25189743511967!3d39.98407353976348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bed09fb8ab873%3A0x5cde0bdf05141db2!2sTandoori%20Grill!5e0!3m2!1sen!2sin!4v1583987491558!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Tandoori Grill"
};
